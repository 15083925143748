import React, { useState } from "react";
import {
  List,
  ListItemButton,
  Collapse,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import {
  Dashboard,
  ManageAccountsOutlined,
  BadgeOutlined,
  Group,
  LanOutlined,
  SchoolOutlined,
  ExpandLess,
  ExpandMore,
  GroupAddOutlined,
  HourglassTop,
  History,
  Task,
  WarehouseOutlined,
  LockOutlined,
  FlightTakeoff,
  Phone,
  FindInPage,
  AirportShuttleOutlined,
  Business,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";

import jwtDecode from "jwt-decode";
import { useAuth } from "../../../../provider/authProvider";
import themeOptions from "../../../../style/componentsStyle";
import translationsEn from "../../../../i18n/translations_en";
import { CalendarIcon } from "@mui/x-date-pickers/icons";

// Define specific keys for the sections
type SectionKey =
  | "employee"
  | "dashboard"
  | "employeeManagement"
  | "employeeOverview"
  | "employees"
  | "teams"
  | "cv"
  | "shiftRecords"
  | "scheduler"
  | "activeDuties"
  | "overtimes"
  | "teamHistory"
  | "myRequest"
  | "absences"
  | "myItems"
  | "inventory"
  | "absenceRequest"
  | "requests"
  | "callDuty"
  | "admin"
  | "travelRequests"
  | "workOrders"
  | "myInventory"
  | "equipment"
  | "companies"
  | "vehicles";

// Type for openSections state
type OpenSectionsState = {
  [key in SectionKey]: boolean;
};

export default function DefaultSidebar() {
  const [openSections, setOpenSections] = useState<OpenSectionsState>({
    employee: false,
    dashboard: false,
    employeeManagement: false,
    employeeOverview: false,
    employees: false,
    teams: false,
    cv: false,
    shiftRecords: false,
    scheduler: false,
    activeDuties: false,
    overtimes: false,
    teamHistory: false,
    myRequest: false,
    absences: false,
    myItems: false,
    inventory: false,
    absenceRequest: false,
    requests: false,
    callDuty: false,
    admin: false,
    workOrders: false,
    myInventory: false,
    equipment: false,
    travelRequests: false,
    vehicles: false,
    companies: false,
  });

  const [selectedItem, setSelectedItem] = useState<SectionKey | null>(null);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const handleItemClick = (section: SectionKey) => {
    setSelectedItem(section);
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const NavItem = ({
    to,
    label,
    icon,
    section,
  }: {
    to: string;
    label: string;
    icon: React.ReactNode;
    section: SectionKey;
  }) => (
    <ListItemButton
      sx={{
        width: "100%",
        backgroundColor: selectedItem === section ? "#02214A" : "transparent",
        color: "white",
      }}
      onClick={() => handleItemClick(section)}
    >
      <NavLink
        to={to}
        style={{
          textDecoration: "none",
          color: "white",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {icon}
        <Typography
          sx={{ marginLeft: 1, fontSize: isSmallScreen ? "small" : "large" }}
        >
          {label}
        </Typography>
      </NavLink>
    </ListItemButton>
  );

  return (
    <aside
      style={{
        width: isSmallScreen ? "60px" : "250px",
        height: "100vh",
        maxHeight: "100vh",
        backgroundColor: themeOptions.palette.sideBar.main,
        overflowX: "scroll",
        overflowY: "auto",
        position: "sticky",
        top: 0,
        transition: "width 0.3s",
        scrollbarWidth: "thin",
      }}
    >
      <List>
        <NavItem
          to="/"
          label="Dashboard"
          icon={<Dashboard />}
          section="dashboard"
        />
        <ListItemButton
          sx={{
            width: "100%",

            color: "white",
            justifyContent: "space-between",
          }}
          onClick={() => handleItemClick("employee")}
        >
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <ManageAccountsOutlined />
            <Typography sx={{ marginLeft: 1, fontSize: "large" }}>
              Employee Management
            </Typography>
          </Grid>

          {openSections.employee ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections.employee} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ paddingLeft: "20px" }}>
            <NavItem
              to="/overview"
              label="Employee Overview"
              icon={<BadgeOutlined />}
              section="employeeOverview"
            />
            <NavItem
              to="/employees"
              label="Employees"
              icon={<Group />}
              section="employees"
            />
            <NavItem
              to="/teams"
              label="Teams"
              icon={<LanOutlined />}
              section="teams"
            />
            <NavItem
              to="/cv"
              label="CV"
              icon={<SchoolOutlined />}
              section="cv"
            />
          </List>
        </Collapse>

        <ListItemButton
          sx={{
            width: "100%",
            backgroundColor:
              selectedItem === "callDuty" ? "#02214A" : "transparent",
            color: "white",
            justifyContent: "space-between",
          }}
          onClick={() => handleItemClick("callDuty")}
        >
          <Grid style={{ display: "flex" }}>
            <HourglassTop />
            <Typography sx={{ marginLeft: 1, fontSize: "large" }}>
              {translationsEn.sidebar.shifts}
            </Typography>
          </Grid>

          {openSections.callDuty ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections.callDuty} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ paddingLeft: "20px" }}>
            <NavItem
              to="/shiftRecords"
              label="Shift records"
              icon={<CalendarIcon />}
              section="shiftRecords"
            />
            <NavItem
              to="/call-duty"
              label="Scheduler"
              icon={<Task />}
              section="scheduler"
            />
          </List>
        </Collapse>
        <ListItemButton
          sx={{
            width: "100%",
            backgroundColor:
              selectedItem === "requests" ? "#02214A" : "transparent",
            color: "white",
            justifyContent: "space-between",
          }}
          onClick={() => handleItemClick("requests")}
        >
          <Grid style={{ display: "flex" }}>
            <GroupAddOutlined />
            <Typography sx={{ marginLeft: 1, fontSize: "large" }}>
              Requests
            </Typography>
          </Grid>

          {openSections.requests ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections.requests} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ paddingLeft: "20px" }}>
            <NavItem
              to="/teamHistoryRequest"
              label="Team History"
              icon={<FindInPage />}
              section="teamHistory"
            />
            <NavItem
              to="/myRequest"
              label="My Requests"
              icon={<Group />}
              section="myRequest"
            />
            <NavItem
              to="/absenceRequest"
              label="Absences"
              icon={<FlightTakeoff />}
              section="absenceRequest"
            />
            <NavItem
              to="/activeDuties"
              label="Active Duties"
              icon={<Phone />}
              section="activeDuties"
            />
            <NavItem
              to="/overtimes"
              label="Overtimes"
              icon={<History />}
              section="overtimes"
            />
          </List>
        </Collapse>
        <ListItemButton
          sx={{
            width: "100%",
            backgroundColor:
              selectedItem === "inventory" ? "#02214A" : "transparent",
            color: "white",
            justifyContent: "space-between",
          }}
          onClick={() => handleItemClick("inventory")}
        >
          <Grid style={{ display: "flex" }}>
            <WarehouseOutlined />
            <Typography sx={{ marginLeft: 1, fontSize: "large" }}>
              Inventory
            </Typography>
          </Grid>

          {openSections.inventory ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections.inventory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ paddingLeft: "20px" }}>
            <NavItem
              to="/my-equipment"
              label="My items"
              icon={<BadgeOutlined />}
              section="myInventory"
            />
            <NavItem
              to="/equipment"
              label="Inventory"
              icon={<LockOutlined />}
              section="equipment"
            />
          </List>
        </Collapse>
        <ListItemButton
          sx={{
            backgroundColor:
              selectedItem === "travelRequests" ? "#02214A" : "transparent",
          }}
        >
          <NavLink
            to="/travel-requests"
            style={{
              textDecoration: "none",
              color: selectedItem === "travelRequests" ? "white" : "white",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleItemClick("travelRequests")}
          >
            <SchoolOutlined />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {translationsEn.sidebar.travelRequests}
            </Typography>
          </NavLink>
        </ListItemButton>
        <ListItemButton
          sx={{
            backgroundColor:
              selectedItem === "workOrders" ? "#02214A" : "transparent",
          }}
        >
          <NavLink
            to="/workOrders"
            style={{
              textDecoration: "none",
              color: selectedItem === "workOrders" ? "white" : "white",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleItemClick("workOrders")}
          >
            <Task />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {translationsEn.sidebar.workOrders}
            </Typography>
          </NavLink>
        </ListItemButton>
        <ListItemButton
          sx={{
            backgroundColor:
              selectedItem === "vehicles" ? "#02214A" : "transparent",
          }}
        >
          <NavLink
            to="/vehicles"
            style={{
              textDecoration: "none",
              color: selectedItem === "vehicles" ? "white" : "white",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleItemClick("vehicles")}
          >
            <AirportShuttleOutlined />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {translationsEn.sidebar.vehicles}
            </Typography>
          </NavLink>
        </ListItemButton>
        <ListItemButton
          sx={{
            backgroundColor:
              selectedItem === "companies" ? "#02214A" : "transparent",
          }}
        >
          <NavLink
            to="/company"
            style={{
              textDecoration: "none",
              color: selectedItem === "companies" ? "white" : "white",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleItemClick("companies")}
          >
            <Business />
            <Typography style={{ marginLeft: "10px", fontSize: "large" }}>
              {translationsEn.sidebar.companies}
            </Typography>
          </NavLink>
        </ListItemButton>
      </List>
    </aside>
  );
}
