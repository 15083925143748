import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import themeOptions from "../../style/componentsStyle";
import translationsEn from "../../i18n/translations_en";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getAllEmployees } from "../../api/employeeApi";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  createTravelRequestStep2,
  getTravelRequestStep2,
} from "../../api/travelRequestApi";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../utils/alertContext";

const PageContainer = styled("div")({
  maxWidth: "900px",
  margin: "auto",
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});

const validationSchema = Yup.object({
  // departureDate: Yup.string().required("Departure date is required"),
  // departureTime: Yup.string().required("Departure time is required"),
  // returnDate: Yup.string().required("Return date is required"),
  // returnTime: Yup.string().required("Return time is required"),
  // durationHours: Yup.number().required("Duration hours is required"),
  // numberOfAllowances: Yup.number().required("Number of allowances is required"),
  // dailyAmount: Yup.number().required("Daily amount is required"),
  // layUpClaim: Yup.string().required("Lay up claim is required"),
  // applyAdvance: Yup.string().required("Apply advance is required"),
  // restForPayment: Yup.string().required("Rest for payment is required"),
  // todayDate: Yup.string().required("Current date is required"),
  // responsiblePersonId: Yup.number().required("Responsible person is required"),
  // submittedById: Yup.number().required("Submitted by is required"),
});

const initialValues = {
  departureDate: "",
  departureTime: "",
  returnDate: "",
  returnTime: "",
  durationHours: "",
  numberOfAllowances: "",
  dailyAmount: "",

  layUpClaim: "",
  applyAdvance: "",
  restForPayment: "",
  todayDate: "",
  responsiblePersonId: "",
  submittedById: "",
};

export default function AdditionalInformation(props?: any) {
  const { travelRequestID } = props;
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [additionalInformation, setAdditionalInformation] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (travelRequestID)
      getTravelRequestStep2(travelRequestID)
        .then((response) => {
          setAdditionalInformation(response.data.data);
        })
        .catch((error) => showAlert(error, "error"));
  }, [travelRequestID]);

  const convertTimeToISO = (time: string): any => {
    const today = new Date().toISOString().split("T")[0];
    const combinedDateTime = `${today}T${time}.000Z`;
    return dayjs(combinedDateTime);
  };

  useEffect(() => {
    if (additionalInformation) {
      formik.setFieldValue(
        "departureDate",
        additionalInformation.departureDate
      );
      formik.setFieldValue(
        "departureTime",
        convertTimeToISO(additionalInformation.departureTime)
      );
      formik.setFieldValue("returnDate", additionalInformation.returnDate);
      formik.setFieldValue(
        "returnTime",
        convertTimeToISO(additionalInformation.returnTime)
      );
      formik.setFieldValue(
        "durationHours",
        additionalInformation.durationHours
      );
      formik.setFieldValue(
        "numberOfAllowances",
        additionalInformation.numberOfAllowances
      );

      formik.setFieldValue("dailyAmount", additionalInformation.dailyAmount);
      formik.setFieldValue("layUpClaim", additionalInformation.layUpClaim);
      formik.setFieldValue("applyAdvance", additionalInformation.applyAdvance);
      formik.setFieldValue(
        "restForPayment",
        additionalInformation.restForPayment
      );
      formik.setFieldValue("todayDate", additionalInformation.todayDate);
      formik.setFieldValue(
        "responsiblePersonId",
        additionalInformation.responsiblePerson?.id
      );
      formik.setFieldValue(
        "submittedById",
        additionalInformation.submittedBy?.id
      );
    }
  }, [additionalInformation]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, action) => {
      createTravelRequestStep2(values, additionalInformation.id)
        .then((response) => {
          props.handleNext();
          showAlert("Additional information added successfully!", "success");
        })
        .catch((error) => showAlert(error, "error"))
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    const filter: any = { companyIds: [] };
    getAllEmployees(filter)
      .then((response) =>
        setUsers(response.data.map((item: any) => item.employeeDto))
      )
      .catch((error) => console.error("Failed to fetch employees:", error));
  }, []);

  return (
    <PageContainer>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
            <span style={{ width: "100%" }}>
              {/* departure day */}
              <DatePicker
                label={translationsEn.createTravelRequests.departureDate}
                format="DD/MM/YYYY"
                value={
                  formik.values.departureDate
                    ? dayjs(formik.values.departureDate)
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue(
                    "departureDate",
                    date ? date.format("YYYY-MM-DD") : ""
                  )
                }
                slotProps={{
                  textField: {
                    helperText:
                      formik.touched.departureDate &&
                      formik.errors.departureDate &&
                      (formik.errors.departureDate as string),
                    error: Boolean(
                      formik.touched.departureDate &&
                        formik.errors.departureDate
                    ),
                  },
                }}
                sx={{ margin: "10px 0", width: "100%" }}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            {/* depaerture time */}
            <TimePicker
              ampm={false}
              label={translationsEn.createTravelRequests.departureTime}
              value={formik.values.departureTime}
              onChange={(time) => formik.setFieldValue("departureTime", time)}
              slotProps={{
                textField: {
                  helperText:
                    formik.touched.departureTime &&
                    formik.errors.departureTime &&
                    (formik.errors.departureTime as string),
                  error: Boolean(
                    formik.touched.departureTime && formik.errors.departureTime
                  ),
                },
              }}
              sx={{ margin: "10px 0", width: "100%" }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* return date */}
            <DatePicker
              label={translationsEn.createTravelRequests.returnDate}
              format="DD/MM/YYYY"
              value={
                formik.values.returnDate
                  ? dayjs(formik.values.returnDate)
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "returnDate",
                  date ? date.format("YYYY-MM-DD") : ""
                )
              }
              slotProps={{
                textField: {
                  helperText:
                    formik.touched.returnDate &&
                    formik.errors.returnDate &&
                    (formik.errors.returnDate as string),
                  error: Boolean(
                    formik.touched.returnDate && formik.errors.returnDate
                  ),
                },
              }}
              sx={{ margin: "10px 0", width: "100%" }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* return time */}
            <TimePicker
              ampm={false}
              label={translationsEn.createTravelRequests.returnTime}
              value={formik.values.returnTime}
              onChange={(time) => formik.setFieldValue("returnTime", time)}
              slotProps={{
                textField: {
                  helperText:
                    formik.touched.returnTime &&
                    formik.errors.returnTime &&
                    (formik.errors.returnTime as string),
                  error: Boolean(
                    formik.touched.returnTime && formik.errors.returnTime
                  ),
                },
              }}
              sx={{ margin: "10px 0", width: "100%" }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* Duration hours */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.durationHours}
              name="durationHours"
              value={
                formik.values.durationHours !== undefined
                  ? formik.values.durationHours
                  : ""
              }
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.durationHours && formik.errors.durationHours
              )}
              helperText={
                formik.touched.durationHours &&
                formik.errors.durationHours &&
                (formik.errors.durationHours as string)
              }
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* number of daily allowances */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.dailyAlowances}
              name="numberOfAllowances"
              value={
                formik.values.numberOfAllowances !== undefined
                  ? formik.values.numberOfAllowances
                  : ""
              }
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.numberOfAllowances &&
                  formik.errors.numberOfAllowances
              )}
              helperText={
                formik.touched.numberOfAllowances &&
                formik.errors.numberOfAllowances &&
                (formik.errors.numberOfAllowances as string)
              }
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* Daily per diem */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.dailyAmount}
              name="dailyAmount"
              value={
                formik.values.dailyAmount !== undefined
                  ? formik.values.dailyAmount
                  : ""
              }
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.dailyAmount && formik.errors.dailyAmount
              )}
              helperText={
                formik.touched.dailyAmount &&
                formik.errors.dailyAmount &&
                (formik.errors.dailyAmount as string)
              }
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            {/* Lay up claim */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.layUpClaim}
              name="layUpClaim"
              value={
                formik.values.layUpClaim !== undefined
                  ? formik.values.layUpClaim
                  : ""
              }
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.layUpClaim && formik.errors.layUpClaim
              )}
              helperText={
                formik.touched.layUpClaim &&
                formik.errors.layUpClaim &&
                (formik.errors.layUpClaim as string)
              }
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* apply advance */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.applyAdvance}
              name="applyAdvance"
              value={
                formik.values.applyAdvance !== undefined
                  ? formik.values.applyAdvance
                  : ""
              }
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.applyAdvance && formik.errors.applyAdvance
              )}
              helperText={
                formik.touched.applyAdvance &&
                formik.errors.applyAdvance &&
                (formik.errors.applyAdvance as string)
              }
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* Rest for payment */}
            <TextField
              type="number"
              margin="dense"
              label={translationsEn.createTravelRequests.restForPayment}
              name="restForPayment"
              value={
                formik.values.restForPayment !== undefined
                  ? formik.values.restForPayment
                  : ""
              }
              onChange={formik.handleChange}
              error={Boolean(
                formik.touched.restForPayment && formik.errors.restForPayment
              )}
              helperText={
                formik.touched.restForPayment &&
                formik.errors.restForPayment &&
                (formik.errors.restForPayment as string)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            {/* Current date */}
            <DatePicker
              label={translationsEn.createTravelRequests.currentDate}
              format="DD/MM/YYYY"
              value={
                formik.values.todayDate ? dayjs(formik.values.todayDate) : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "todayDate",
                  date ? date.format("YYYY-MM-DD") : ""
                )
              }
              slotProps={{
                textField: {
                  helperText:
                    formik.touched.todayDate &&
                    formik.errors.todayDate &&
                    (formik.errors.todayDate as string),
                  error: Boolean(
                    formik.touched.todayDate && formik.errors.todayDate
                  ),
                },
              }}
              sx={{ margin: "10px 0", width: "100%" }}
            />
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
            <span style={{ width: "100%" }}>
              {/* Responsible person */}
              <Autocomplete
                options={users}
                getOptionLabel={(option: any) =>
                  `${option.firstName} ${option.lastName}`
                }
                value={
                  formik.values.responsiblePersonId
                    ? users.find(
                        (user: any) =>
                          user.id === formik.values.responsiblePersonId
                      )
                    : null
                }
                onChange={(event, value: any) => {
                  formik.setFieldValue("responsiblePersonId", value.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      formik.touched.responsiblePersonId &&
                        formik.errors.responsiblePersonId
                    )}
                    helperText={
                      formik.touched.responsiblePersonId &&
                      formik.errors.responsiblePersonId &&
                      (formik.errors.responsiblePersonId as string)
                    }
                    label={
                      translationsEn.createTravelRequests.responsiblePerson
                    }
                    placeholder={
                      translationsEn.createTravelRequests.inputResponsiblePerson
                    }
                  />
                )}
              />
            </span>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
            <span style={{ width: "100%" }}>
              {/* Submitted by */}
              <Autocomplete
                options={users}
                getOptionLabel={(option: any) =>
                  `${option.firstName} ${option.lastName}`
                }
                value={
                  formik.values.submittedById
                    ? users.find(
                        (user: any) => user.id === formik.values.submittedById
                      )
                    : null
                }
                onChange={(event, value: any) => {
                  formik.setFieldValue("submittedById", value.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      formik.touched.submittedById &&
                        formik.errors.submittedById
                    )}
                    helperText={
                      formik.touched.submittedById &&
                      formik.errors.submittedById &&
                      (formik.errors.submittedById as string)
                    }
                    label={translationsEn.createTravelRequests.submittedBy}
                    placeholder={
                      translationsEn.createTravelRequests.inputPerson
                    }
                  />
                )}
              />
            </span>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              size="large"
              style={{
                width: "100%",
                backgroundColor: "white",
                color: themeOptions.palette.primary.main,
                borderColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              disabled={loading}
              onClick={() => navigate("/travel-requests")}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                translationsEn.createTravelRequests.cancel
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{
                width: "100%",
                backgroundColor: themeOptions.palette.primary.main,
                textTransform: "none",
                marginTop: "20px",
              }}
              disabled={loading}
            >
              {translationsEn.createTravelRequests.submit}
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageContainer>
  );
}
