import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

import translationsEn from "../../i18n/translations_en";
import themeOptions from "../../style/componentsStyle";

import { useAuth } from "../../provider/authProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { BookmarkAdd, Clear } from "@mui/icons-material";
import { IRequestType } from "../../interfaces/overview.interface";
import {
  createVocationDays,
  getVocationDaysRequest,
  updateVocationDays,
} from "../../api/overviewApi";
import { useAlert } from "../../utils/alertContext";

type RequestTypeName =
  | "vacation"
  | "blood_donation"
  | "parental_leave"
  | "plan"
  | "sick_leave"
  | "student_absence"
  | "remote_work"
  | "paid_leave";

const requestValidationSchema = Yup.object().shape({
  person: Yup.string().required("Person is required"),
  requestType: Yup.string().required("Request type is required"),
  startDate: Yup.date()
    .nullable()
    .required("Start date is required")
    .test(
      "is-weekend",
      "Start date cannot be on a weekend",
      (value) => dayjs(value).weekday() !== 0 && dayjs(value).weekday() !== 6
    ),
  endDate: Yup.date()
    .nullable()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .test(
      "is-weekend",
      "End date cannot be on a weekend",
      (value) => dayjs(value).weekday() !== 0 && dayjs(value).weekday() !== 6
    ),
});

interface AddNewRequestProps {
  open: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
  requestData?: any;
  persons: any;
  requestTypes: any;
  paidLeaveTypes: any;
}

const AddNewRequestModal: React.FC<AddNewRequestProps> = ({
  open,
  handleClose,
  onSuccess,
  requestData = {},
  persons,
  requestTypes,
}) => {
  dayjs.extend(weekday);
  const { loggedUserId } = useAuth();
  const { showAlert } = useAlert();
  const mode = requestData && requestData.id ? "edit" : "new";

  const initialValues = {
    id: requestData.id || null,
    person: requestData.person || loggedUserId,
    requestType: requestData.requestType || "",
    paidLeaveType: requestData.paidLeaveType || "",
    startDate: requestData.startDate || undefined,
    endDate: requestData.endDate || undefined,
    note: requestData.note || "",
    remoteWorkTypeId: requestData.remoteWorkTypeId || "",
    paidLeaveTypeId: requestData.paidLeaveTypeId || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: requestValidationSchema,
    onSubmit: (values) => {
      const request = {
        ...getVocationDaysRequest(values),
        remoteWorkTypeId: values.remoteWorkTypeId,
        paidLeaveTypeId: values.paidLeaveTypeId,
      };

      const handleError = (error: any, action: string) => {
        const errorMessage = error.response?.data?.error
          ? error.response.data.error
          : JSON.stringify(error.response?.data);

        showAlert(`Failed to ${action} request. ${errorMessage}`, "error");
        console.error(`Error ${action} request:`, errorMessage);
      };

      if (request.id) {
        updateVocationDays(request)
          .then((response) => {
            if (onSuccess) {
              onSuccess();
            }
            handleModalClose();
          })
          .catch((error) => handleError(error, "update"));
      } else {
        createVocationDays(request)
          .then((response) => {
            if (onSuccess) {
              onSuccess();
            }
            handleModalClose();
          })
          .catch((error) => handleError(error, "create"));
      }
    },
  });

  console.log(formik.values.requestType, "request");

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          style={{
            padding: "20px",
            textAlign: "center",
            width: "20%",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <BookmarkAdd style={{ marginRight: "5px" }} />
                <Typography>
                  {mode === "edit"
                    ? translationsEn.teamRequest.editRequest
                    : translationsEn.teamRequest.newRequest}
                </Typography>
              </Box>

              <Clear onClick={handleModalClose} />
            </Box>
            <hr></hr>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormControl
                style={{ width: "100%", margin: "10px" }}
                error={Boolean(formik.touched.person && formik.errors.person)}
              >
                <InputLabel id="demo-simple-select-label">Person</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Person"
                  value={formik.values.person}
                  onChange={(e: any) => {
                    formik.setFieldValue("person", e.target.value);
                  }}
                >
                  {persons.map((person: any) => (
                    <MenuItem key={person.id} value={person.id}>
                      {person.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.person && formik.errors.person && (
                  <FormHelperText>
                    {formik.errors.person as string}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                style={{ width: "100%", margin: "10px" }}
                error={Boolean(
                  formik.touched.requestType && formik.errors.requestType
                )}
              >
                <InputLabel id="request-type-label">Request type</InputLabel>
                <Select
                  labelId="request-type-label"
                  label="Request type"
                  value={formik.values.requestType}
                  onChange={(e: any) => {
                    formik.setFieldValue("requestType", e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  {requestTypes.map((requestType: IRequestType) => (
                    <MenuItem key={requestType.id} value={requestType.id}>
                      {translationsEn.requestTypes[
                        requestType.name as RequestTypeName
                      ] || requestType.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.requestType && formik.errors.requestType && (
                  <FormHelperText>
                    {formik.errors.requestType as string}
                  </FormHelperText>
                )}
              </FormControl>

              {formik.values.requestType === 7 && (
                <FormControl
                  style={{ width: "100%", margin: "10px" }}
                  error={Boolean(
                    formik.touched.remoteWorkTypeId &&
                      formik.errors.remoteWorkTypeId
                  )}
                >
                  <InputLabel id="remote-work-type-label">
                    Remote Work Type
                  </InputLabel>
                  <Select
                    labelId="remote-work-type-label"
                    label="Remote Work Type"
                    value={formik.values.remoteWorkTypeId || ""}
                    onChange={(e: any) => {
                      formik.setFieldValue("remoteWorkTypeId", e.target.value);
                    }}
                  >
                    <MenuItem value={1}>Home Office</MenuItem>
                    <MenuItem value={2}>Remote Location</MenuItem>
                  </Select>
                  {formik.touched.remoteWorkTypeId &&
                    formik.errors.remoteWorkTypeId && (
                      <FormHelperText>
                        {formik.errors.remoteWorkTypeId as string}
                      </FormHelperText>
                    )}
                </FormControl>
              )}

              {formik.values.requestType === 3 && (
                <FormControl
                  style={{ width: "100%", margin: "10px" }}
                  error={Boolean(
                    formik.touched.paidLeaveTypeId &&
                      formik.errors.paidLeaveTypeId
                  )}
                >
                  <InputLabel id="remote-work-type-label">
                    Remote Work Type
                  </InputLabel>
                  <Select
                    labelId="remote-work-type-label"
                    label="Paid leave type"
                    value={formik.values.paidLeaveTypeId || ""}
                    onChange={(e: any) => {
                      formik.setFieldValue("paidLeaveTypeId", e.target.value);
                    }}
                  >
                    <MenuItem value={1}>Marriage leave</MenuItem>
                    <MenuItem value={2}>Child birth leave</MenuItem>
                    <MenuItem value={3}>Family sickness leave</MenuItem>
                    <MenuItem value={4}>Family member death leave</MenuItem>
                    <MenuItem value={5}>Relocation to the same city</MenuItem>
                    <MenuItem value={6}>
                      Relocation to a different city
                    </MenuItem>
                    <MenuItem value={7}>Natural disaster</MenuItem>
                    <MenuItem value={8}>Professional education leave</MenuItem>
                    <MenuItem value={9}>Personal needs</MenuItem>
                    <MenuItem value={10}>
                      Parents family member death leave
                    </MenuItem>
                    <MenuItem value={11}>
                      Other close family sickness leave
                    </MenuItem>
                    <MenuItem value={12}>Court call leave</MenuItem>
                    <MenuItem value={13}>
                      Child kindergarten or school adaptation leave
                    </MenuItem>
                  </Select>
                  {formik.touched.paidLeaveTypeId &&
                    formik.errors.paidLeaveTypeId && (
                      <FormHelperText>
                        {formik.errors.paidLeaveTypeId as string}
                      </FormHelperText>
                    )}
                </FormControl>
              )}
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <DatePicker
                  label={translationsEn.labels.startDate}
                  format="DD/MM/YYYY"
                  minDate={null}
                  value={
                    formik.values.startDate
                      ? dayjs(formik.values.startDate)
                      : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "startDate",
                      date ? date.toISOString() : undefined
                    )
                  }
                  sx={{ margin: "10px" }}
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.startDate &&
                        formik.errors.startDate &&
                        (formik.errors.startDate as string),
                      error: Boolean(
                        formik.touched.startDate && formik.errors.startDate
                      ),
                    },
                  }}
                />

                <DatePicker
                  label={translationsEn.labels.endDate}
                  format="DD/MM/YYYY"
                  minDate={dayjs(formik.values.startDate)}
                  value={
                    formik.values.endDate ? dayjs(formik.values.endDate) : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "endDate",
                      date ? date.toISOString() : undefined
                    )
                  }
                  sx={{ margin: "10px" }}
                  slotProps={{
                    textField: {
                      helperText:
                        formik.touched.endDate &&
                        formik.errors.endDate &&
                        (formik.errors.endDate as string),
                      error: Boolean(
                        formik.touched.endDate && formik.errors.endDate
                      ),
                    },
                  }}
                />
              </Grid>

              <TextField
                id="note"
                label="Note"
                variant="standard"
                value={formik.values.note}
                onChange={formik.handleChange}
                style={{
                  width: "90%",
                }}
              />
            </Box>
            <hr></hr>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: themeOptions.palette.confirm.main,
                  ":hover": {
                    backgroundColor: themeOptions.palette.confirm.hover,
                  },
                }}
              >
                {translationsEn.buttons.save}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddNewRequestModal;
